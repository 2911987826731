.p-multiselect{
    @apply border-error-2;
    @apply bg-surface-default border !border-neutral-5 transition-colors duration-200 ease-in-out rounded-lg;
    
}

.p-multiselect.p-invalid.p-component {
   @apply border-error-2 !important;
}


.p-multiselect {
    border: 1px solid;
}