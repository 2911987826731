.p-checkbox-box {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid;
  @apply text-primary;
}

.p-checkbox-input,
.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-highlight .p-checkbox-box {
  @apply !bg-primary;
}
