.confirm-dialog {
  .p-dialog-header {
    border-radius: 12px 12px 0 0;
  }

  .p-dialog-content {
    margin-top: -1px;
    border-radius: 0 0 12px 12px;
  }
}

@media (max-width: 640px) {
  .confirm-dialog {
    position: absolute !important;
    bottom: 0;
    top: unset !important;
    left: unset !important;
    transform: none !important;
    .p-dialog-content {
      margin-top: -1px;
      border-radius: 0 !important;
    }
  }
}
