.quick-quote-form {
  @media (max-width: 640px) {
    .p-inputtext {
      @apply border-r-0 rounded-tr-none rounded-br-none;
    }
    .p-dropdown {
      @apply border-l-0 rounded-tl-none rounded-bl-none;
    }
    .label {
      @apply opacity-0;
    }
  }

  .p-inputtext {
    @apply shadow-none;
  }
}
