.p-tabview-nav {
  justify-content: start;
  background: inherit;
  margin-top: 20px;
}

.p-tabview-panels {
  background: inherit;
  padding: 0;
  padding-top: 1.25rem;
}

.p-unselectable-text {
  @apply text-neutral-3 border-b-[1px] border-neutral-border;
}

.p-tabview-nav-link {
  @apply text-sm-medium;
  background: inherit;
  border: inherit;
  justify-content: center;
}

.p-tabview-selected {
  @apply !border-b-2 !border-primary-gradient-from !text-primary-gradient-from;
}

.p-tabview-selected a {
  @apply !bg-primary-surface-light;
}
