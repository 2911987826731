.p-multiselect-label {
  display: flex;
}
.p-chip {
  @apply bg-neutral-surface-dark;
}

.p-multiselect-label {
  overflow: auto;
}

.p-multiselect {
  overflow: auto;
}

.p-multiselect .p-multiselect-label::-webkit-scrollbar {
  height: 6px;
}
