.menu-button {
  @apply flex items-center cursor-pointer p-3 rounded-md w-full hover:bg-menu-hover transition duration-200;
}

.menu-item-selected {
  @apply bg-menu-selected;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

input[type="checkbox"]:checked + label + .submenu {
  display: block;
  margin-left: 20px; /* Indentation for submenu items */
}

input[type="checkbox"]:checked + label i {
  transform: rotate(180deg); /* Rotates the arrow */
}

.inner-menu {
  @apply flex items-center cursor-pointer p-3 rounded-c8;
}

@keyframes slidedown {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes slideup {
  0% {
    max-height: 500px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
  }
}

.slidedown {
  animation: slidedown 0.45s ease-in-out forwards;
  overflow: hidden;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1) forwards;
  overflow: hidden;
}

.submenu {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.45s ease-in-out, opacity 0.45s ease-in-out;
}
