.custom-currency-picklist {
    .p-picklist-list-wrapper {
      @apply bg-background rounded-c8;
    }
  
    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
      @apply px-6 py-4;
    }
  
    .p-picklist-list {
      @apply bg-white rounded-c8 border border-neutral-surface-dark mt-2;
    }
  
    .p-picklist-header {
      @apply p-0 pb-3 text-neutral-2;
    }
  
    .p-picklist-filter-container {
      @apply p-0;
  
      .p-picklist-filter-input {
        @apply rounded-c8 border border-neutral-border p-2;
      }
    }
  
    .p-picklist-transfer-buttons {
      @apply flex flex-col gap-6;
  
      .p-button {
        @apply bg-transparent text-primary;
  
        &:hover {
          @apply bg-primary-surface-light;
        }
  
        span {
          display: none;
        }
      }
    }
  
    ul {
      @apply pl-2 py-2;
  
      li {
        @apply rounded-c8;
      }
    }
  }
  
  .p-picklist-buttons {
    .p-button:nth-child(1) {
      order: 2;
    }
  
    .p-button:nth-child(2) {
      order: 1;
    }
  
    .p-button:nth-child(3) {
      order: 3;
    }
  
    .p-button:nth-child(4) {
      order: 4;
    }
  }
  
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-neutral-border;
    border-radius: 5px;
  }
  