:root {
  --primary-surface-dark: #070d40;
  --primary-surface-dark-content: #ffffff;
  --primary-surface-dark-hover: #3d4591;
  --primary-surface-dark-selected: #262d6e;
  --primary-color: #0d1775;
  --primary-color-hover: #1f2b9b;
  --inuput-hover: #c1c1c5;
  --primary-disabled-color: #b4b7d4;
  --primary-text-color: #ffffff;
  --primary-color-light: #92c6fa;
  --primary-surface-light: #f0f8fe;
  --primary-gradient-from: #0d1775;
  --primary-gradient-to: #1383f4;
  --primary-dark-content: #0d5dad;
  --primary-blue: #b6d9fc;
  --primary-blue-surface-dark: #0a4886;
  --border-radius: 6px;
  --secondary-color: #ffffff;
  --secondary-text-color: #0f172a;
  --secondary-border-color: #c9d2de;
  --error-border-subtle: #ce0d0d;
  --error-content-default: #eb0000;
  --error-surface-light: #fef6f6;
  --warning-content-default: #ffa000;
  --warning-surface-light: #fef9f0;
  --warning-content-dark: #85570e;
  --success-content-default: #078a04;
  --success-surface-light: #f1f8f1;
  --checkmark-fill: #126bd7;
  --avatar-backgrund-light: #e5e7eb;
  --client-logo-expanded: url("../../../public/images/logo_stonex.png");
  --client-logo-expanded-dark: url("../../../public/images/logo_stonex_dark.png");
  --client-logo-collapsed: url("../../../public/images/logo_stonex_collapsed.png");
  --warning-border-subtle: #F29E1A;
  --font-family: "Inter";
  color-scheme: light;
}
