.custom-select {
    @apply inline-block p-1 bg-neutral-surface-subtle border border-neutral-surface-dark rounded-c8
}

.custom-select .p-button {
    @apply bg-neutral-surface-subtle text-neutral-3 rounded-c8;
}

.custom-select .p-button.p-highlight {
    @apply bg-white text-neutral-2;
}

.custom-select .p-button.p-focus {
    @apply shadow-none;
}