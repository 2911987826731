.p-menu {
  @apply px-[8px];
  @apply min-w-[228px];
  z-index: 1500 !important;
}

.p-menuitem-content {
  @apply rounded-c8;

  .p-menuitem-link {
    width: max-content;
  }

  svg {
    display: inline-block;
    width: '32px';
    @apply fill-primary-dark-content;
  }

  @apply text-neutral-2;
  @apply text-sm-medium;
}

.p-menuitem-content:hover {
  @apply !bg-primary-surface-light;
}

.p-menuitem-content:focus {
  @apply bg-primary-surface-light;
}

.p-menu
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
  > .p-menuitem-content {
  @apply bg-transparent;
}

.delete-menu-item {
  @apply text-neutral-2;
  @apply text-sm-medium;

  .p-menuitem-link {
    @apply text-error-1;

    .p-menuitem-text {
      @apply text-error-1;
    }
  }
}

.delete-menu-item {
  svg {
    @apply fill-error-1;
  }
}
