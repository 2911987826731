.approvals {
  .p-tabview-nav {
    justify-content: start;
  }

  .p-unselectable-text {
    width: min-content;
  }

  .p-tabview-panels {
    padding-left: 0 !important;
  }
}

.view-approvers-tabs {
  .p-tabview {
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .p-tabview-panels {
    padding-inline: 0;
    overflow: hidden;

    .p-tabview-panel {
      height: 100%;
      overflow: hidden;
    }
  }
}

.horizontal-stepper .p-steps-item::before {
  height: 1px;
  z-index: 1;
  @apply bg-neutral-5;
}

.horizontal-stepper .p-highlight {
  @apply bg-transparent;
}

.horizontal-stepper .p-steps-item::before {
  background-color: transparent;
}

.horizontal-stepper .p-steps-item {
  width: 25%;
  position: relative;
}
