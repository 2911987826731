.content {
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    margin-left: 0;
    width: 100%
}

.content-shift {
    margin-left: 22.286rem;
    width: calc(100% - 22.286rem);
}

.custom-sidebar:not(.p-sidebar-visible) {
    display: none;
}

.content-collapsed {
    margin-left: 6rem;
    width: calc(100% - 6rem);
}