tbody td {
  padding: 16px 16px 16px 8px;
  @apply h-[72px] text-neutral-content-text2 text-sm-regular;
}

tbody tr {
  @apply border-t-[1px] border-neutral-border;
}

th {
  padding: 0px 16px 0px 8px;
  @apply border-y-[1px] border-neutral-border text-xs-medium h-[48px];
}

table .p-checkbox-box {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid;
  @apply border-neutral-4;
}

.p-datatable {
  @apply rounded-c8 shadow-c bg-white px-0 pb-[10px];
}

.p-datatable-header {
  @apply rounded-t-[8px] shadow-c bg-white;
}

.p-selection-column {
  padding-right: 12px;
  padding-left: 20px;
}
.p-dialog-header-close {
  svg {
    @apply text-neutral-3;
  }
}

.full-screen-dialog {
  .p-dialog-content {
    padding-bottom: 0;
  }

  .p-dialog {
    max-height: 100%;
  }

  .p-dialog-header {
    border-radius: 0;
    padding: 0;
  }

  .p-dialog-footer {
    border-radius: 0;
  }

  .p-dialog-header-icons {
    align-self: center;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  @apply shadow-none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  @apply !bg-primary-surface-light;
  @apply text-neutral-2;

}


.aobo-dropdown .p-placeholder .p-label {
  @apply text-xs-regular;
}

.idp {
  label {
    @apply text-sm-semibold;
  }
}

.global-search {
  input {
    @apply h-12;
  }
}