.p-datatable {
  border: 1px solid;
  @apply !border-neutral-border;
  border-radius: 12px;
  box-shadow: none !important;
  padding-bottom: 0;
}

th {
  border: none;
}

th:first-child {
  border-top-left-radius: 12px !important;
  padding-left: 16px !important;
}

td:first-child {
  padding-left: 16px !important;
}

th:last-child {
  border-top-right-radius: 12px !important;
}
