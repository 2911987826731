.managed-pricing {
    .p-tabview-nav {
        justify-content: start;
    }

    .p-unselectable-text {
        width: min-content;
    }

    .p-tabview-panels{
        padding-left: 0 !important;
    }
}