.notifications-table.p-datatable {
    @apply rounded-none border-none border-0;

  .p-datatable-thead {
    @apply hidden;
  }

  .p-datatable-header {
    @apply border-b border-neutral-border rounded-none min-h-20;
  }

  tbody { 
    @apply flex flex-col gap-3 py-3 bg-background;
  }

  tr {
    @apply border-0 rounded-c8 align-top flex flex-row;
  }

  td {
    @apply h-fit pb-3;
  }

  td.profile {
    padding-top: 1.3rem;
  }
}
